@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'animations';

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html,
body,
#root {
  min-height: 100vh;
}

.grid-vacancies {
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}

.upload-form {
  font-size: 30px;
}
